import React, { useState, useEffect } from 'react';
import './Video.scss';
import classNames from 'classnames';
import { Cursor } from 'components/Cursor';

export const Video = ({ videoSrc, isAlpha, cursorDirection }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [displayPlay, setDisplayPlay] = useState(true);

  useEffect(() => {
    setIsVisible(true);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setDisplayPlay(false);
    }, 1000);
  }, []);

  return (
    <>
      <video
        autoPlay
        muted
        width="100%"
        height="100%"
        className={classNames({
          'video--notvisible': !isVisible,
          video: !isAlpha,
          'video--alpha': isAlpha,
        })}
      >
        <source src={videoSrc} type="video/mp4" />
      </video>
      {displayPlay && (
        <img
          className={classNames(
            displayPlay ? 'video__play-button' : 'video__play-button--visible'
          )}
          src="/Polygon Play.svg"
          alt="play button"
        />
      )}
      <Cursor cursorDirection={cursorDirection} />
    </>
  );
};
