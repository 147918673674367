import { useRef } from 'react';
import { Carousel, Footer, Intro } from 'components';
import { cases } from 'data';
import './App.scss';
import { useWindowSize } from 'hooks';

function App() {
  const sectionRefs = useRef({});
  const topRef = useRef(null);

  const { isMobileScreen } = useWindowSize();

  const setSectionRef = (caseName) => (ref) => {
    sectionRefs.current[caseName] = ref;
  };

  const scrollToElement = () => {
    if (topRef.current) {
      topRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className="app">
      <section ref={topRef}>
        <Intro sectionRefs={sectionRefs} />
      </section>

      {cases.map(({
        id,
        title,
        type, 
        images, 
        imagesMobile, 
        isAlpha, 
        link,
        behance,
      }) => (
        <section key={id} ref={setSectionRef(id)}>
          <Carousel
            title={title}
            type={type}
            images={isMobileScreen ? imagesMobile : images}
            isAlpha={isAlpha}
            link={link}
            behance={behance}
          />
        </section>
      ))}

      <section name="footer">
        <Footer
          scrollToElement={scrollToElement}
        />
      </section>
    </div>
  );
}

export default App;
