import React, { useEffect, useRef } from 'react';
import './PreviewLink.scss';
import classNames from 'classnames';
import { getRandomNumber } from 'utils/getRandomNumber';

export const PreviewLink = ({ caseRef, imgSrc, ind, sectionRefs }) => {
  const mainRef = useRef(null);
  useEffect(() => {
    if (mainRef.current) {
      mainRef.current.style.top = `${getRandomNumber(0, 30)}%`;
      mainRef.current.style.left = `${getRandomNumber(0, 30)}%`;
    }
  }, []);

  const scrollToSection = (refToCase) => {
    if (refToCase) {
      refToCase.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleClick = () => {
    scrollToSection(sectionRefs.current[caseRef]);
  };

  return (
    <div
      ref={mainRef}
      className={classNames(`preview-link grid-item--${ind + 1}`)}
    >
      <img
        src={imgSrc}
        alt="case"
        className="preview-link__img"
        onClick={handleClick}
      />
    </div>
  );
};
