import React from 'react';
import { PreviewLink } from 'components/PreviewLink';
import './Intro.scss';
import { Grid } from 'components/Grid';
import { cases } from 'data';
import { Header } from 'components/Header';

export const Intro = ({ sectionRefs }) => {
  const previewLinks = cases.map(({ id, imagesMobile }) => {
    return [id, imagesMobile[0]];
  });
  return (
    <div className="intro" id="top">
      {/* <div className="intro__header">
        <div className="intro__name">Stasia Lutova</div>
        <div className="intro__description">
          <p className="intro__description-roles">
            Identity Builder / Graphic and Web Designer / Art Director
          </p>

          <p className="intro__description-moto">
            Designing for emotional pleasure and financial success.
          </p>

          <p className="intro__description-location">
            Based in Ukraine, creating worldwide.
          </p>
        </div>
      </div> */}
      {/* <div
        style={{
          position: 'absolute',
          width: '50%',
          backgroundColor: 'pink',
          height: '100vh',
        }}
      >
        hey
      </div> */}

      <Header caseRef={sectionRefs} />

      <Grid>
        {previewLinks.map(([caseName, photo], ind) => (
          <PreviewLink
            key={caseName}
            caseRef={caseName}
            imgSrc={photo}
            ind={ind}
            sectionRefs={sectionRefs}
          />
        ))}
      </Grid>
    </div>
  );
};
