import React, { useEffect } from 'react';
import './Message.scss';

export const Message = ({ setShowMessage }) => {
  useEffect(() => {
    const messageTimer = setTimeout(() => {
      setShowMessage(false);
    }, 1500);

    return () => {
      clearTimeout(messageTimer);
    };
  });

  const showingMessage = () => {
    setShowMessage(false);
  };

  return (
    <div onClick={showingMessage} className="message">
      Email Copied!
    </div>
  );
};
