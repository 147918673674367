import React from 'react';
import './Contacts.scss';

export const Contacts = ({ setShowMessage }) => {
  const copyEmailToClipboard = () => {
    navigator.clipboard.writeText('stasialutova@gmail.com');
    setShowMessage(true);
  };
  
  return (
    <div className="contacts">
      <div className="contacts__title">Contact:</div>
      <div className="contacts__email" onClick={copyEmailToClipboard}>
        Email,
      </div>

      <a
        href="https://www.instagram.com/stasia_lutova/"
        className="contacts__email"
        target="_blank"
        rel="noopener noreferrer"
      >
        Instagram,
      </a>

      <a
        href="https://www.behance.net/lutova"
        target="_blank"
        rel="noopener noreferrer"
        className="contacts__instagram contacts__instagram--behance"
      >
        Bēhance
      </a>
    </div>
  );
};
