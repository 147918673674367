import React, { useEffect, useRef } from 'react';
import './Cursor.scss';
import classNames from 'classnames';
import { useMousePosition } from 'hooks/useMousePosition';


export const Cursor = ({ cursorDirection }) => {
  const { x, y } = useMousePosition();
  const cursorRef = useRef(null);

  useEffect(() => {
    if (cursorRef.current) {
      cursorRef.current.style.top = y - 80 + 'px';
      cursorRef.current.style.left = x - 20 + 'px';
    }
  });

  return (
    <div
      ref={cursorRef}
      className={classNames('cursor', {
        'cursor--left': cursorDirection === 'left',
      })}
    />
  );
};
