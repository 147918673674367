import React, { useState } from 'react';
import './Header.scss';
import { Contacts } from 'components/Contacts';
import { Message } from 'components/Message';

export const Header = ({ caseRef }) => {
  const [showMessage, setShowMessage] = useState(false);

  const scrollToSection = (refToCase) => {
    if (refToCase) {
      refToCase.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleClick = () => {
    scrollToSection(caseRef.current[1]);
  };

  return (
    <div className="header">
      <p className="header__text">
        <span> </span>
        I'm a Brand and Web Designer whose practice focused mainly on a creating
        consistent, timeless yet contemporary visual systems that help brands
        communicate their special qualities, charisma and purpose.<span> </span>
        <p className="header__text-italic">
          One of the most pleasant experiences for me is visual catharsis. It
          can help people get rid of the fear of life even for a moment.
        </p>
        <span> </span>
        And I would like to create this experience with you.
      </p>
      <div className="header__bottom">
        <div className="header__info">
          <div className="header__name">Stasia Lutova</div>

          <p className="header__expirience">
            Identity Builder and Graphic Designer—6 years
            <br />
            Art Director—2 years
            <br />
            Web-Designer—1 year
          </p>

          <p className="header__location">
            Based in a safe city in Ukraine,
            <br /> creating for brands worldwide.
          </p>
        </div>

        <div className="header__footer">
          <p className="header__empty"></p>
          <p className="header__portfolio" onClick={handleClick}>
            Portfolio ↓
          </p>
          <Contacts setShowMessage={setShowMessage} />
        </div>
      </div>

      {showMessage && <Message setShowMessage={setShowMessage} />}
    </div>
  );
};
