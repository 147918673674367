import React, { useState, useRef, useEffect } from 'react';
import { mediaTypeChecker } from 'utils/mediaTypeChecker';
import { Picture, Video } from './components';
import './Carousel.scss';

export const Carousel = ({ title, type, link, behance, images, isAlpha }) => {
  const [currentPhoto, setCurrentPhoto] = useState(0);
  const [cursorDirection, setCursorDirection] = useState('right');

  const touchStartX = useRef(null);

  const totalImages = images.length;

  const preloadNextImage = (ind) => {
    const nextPhotoIndex = (currentPhoto + ind) % totalImages;
    const nextImage = new Image();
    nextImage.src = images[nextPhotoIndex];
  };

  useEffect(() => {
    preloadNextImage(1);
    preloadNextImage(totalImages - 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleImageClick = (event) => {
    const { clientX, target } = event;
    const { left, width } = target.getBoundingClientRect();
    const clickPosition = clientX - left;

    const threshold = width / 2;

    if (clickPosition > threshold) {
      nextPhoto();
    } else {
      prevPhoto();
    }
  };

  const handleMouseMove = (event) => {
    const { clientX, target } = event;
    const { left, width } = target.getBoundingClientRect();
    const cursorPosition = clientX - left;

    const threshold = width / 2;

    if (cursorPosition > threshold) {
      setCursorDirection('right');
    } else {
      setCursorDirection('left');
    }
  };

  const handleTouchStart = (event) => {
    touchStartX.current = event.touches[0].clientX;
  };

  const handleTouchEnd = (event) => {
    if (touchStartX.current === null) {
      return;
    }

    const touchEndX = event.changedTouches[0].clientX;
    const touchDiff = touchEndX - touchStartX.current;

    if (touchDiff > 0) {
      prevPhoto();
    } else if (touchDiff < 0) {
      nextPhoto();
    }

    touchStartX.current = null;
  };

  function nextPhoto() {
    if (currentPhoto === totalImages - 1) {
      setCurrentPhoto(0);
      return;
    }
    setCurrentPhoto((prev) => prev + 1);
    preloadNextImage(2);
  }

  function prevPhoto() {
    if (currentPhoto === 0) {
      setCurrentPhoto(totalImages - 1);
      return;
    }

    setCurrentPhoto((prev) => prev - 1);
    preloadNextImage(currentPhoto - 1);
  }

  return (
    <div className="carousel">
      <div className="carousel__header">
        <div className="carousel__title">
          <p className="carousel__title-paragraph">{title}</p>
          <p className="carousel__title-paragraph">{type}</p>
        </div>
        <div className="carousel__counter">
          <div className="carousel__current-photo">{currentPhoto + 1}</div>
          <div className="carousel__slash">/</div>
          <div>{totalImages}</div>
        </div>
        <div className="carousel__links">
          <a
            href={link}
            className="carousel__link"
            target="_blank"
            rel="noopener noreferrer"
          >
            {link && 'Website'}
          </a>
          <a
            href={behance}
            className="carousel__link"
            target="_blank"
            rel="noopener noreferrer"
          >
            Full Case
          </a>
        </div>
      </div>

      <div
        className="carousel__img-container"
        onClick={handleImageClick}
        onMouseMove={handleMouseMove}
        onTouchStart={handleTouchStart}
        onTouchEnd={handleTouchEnd}
      >
        {images
          .slice(currentPhoto, currentPhoto + 1)
          .map((photo) =>
            mediaTypeChecker(photo) ? (
              <Video
                key={photo}
                videoSrc={photo}
                isAlpha={isAlpha}
                cursorDirection={cursorDirection}
              />
            ) : (
              <Picture
                cursorDirection={cursorDirection}
                key={photo}
                imgSrc={photo}
              />
            )
          )}
      </div>
    </div>
  );
};
