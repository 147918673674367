/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect, memo } from 'react';
import './Picture.scss';
import { Cursor } from 'components/Cursor';
import classNames from 'classnames';

export const Picture = memo(({ imgSrc, cursorDirection }) => {
  const [active, setActive] = useState(false);
  const [loaded, setLoaded] = useState(false);

  const originalImagePath = imgSrc;

  useEffect(() => {
    const img = new Image();
    img.src = originalImagePath;
    img.onload = () => {
      setLoaded(true);
      setTimeout(() => {
        setActive(true);
      }, 10);
    };
  }, [originalImagePath]);

  return (
    <>
      {!loaded ? (
        <div className="picture__loader" />
      ) : (
        <>
          <img
            src={originalImagePath}
            className={classNames(
              active ? 'picture__img--active' : 'picture__img'
            )}
            loading="lazy"
          />
          <Cursor cursorDirection={cursorDirection} />
        </>
      )}
    </>
  );
});
