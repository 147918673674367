export const cases = [
  {
    id: 1,
    title: 'Enium',
    type: 'Brand Identity',
    link: 'https://enium.com/',
    behance: 'https://www.behance.net/gallery/186064747/ENIUM',
    images: [
      '/photos/1/1.jpg',
      '/photos/1/2.jpg',
      '/photos/1/3.jpg',
      '/photos/1/4.jpg',
      '/photos/1/5.jpg',
      '/photos/1/6.jpg',
    ],
    imagesMobile: [
      '/photos/1/mobile/1.jpg',
      '/photos/1/mobile/2.jpg',
      '/photos/1/mobile/3.jpg',
      '/photos/1/mobile/4.jpg',
      '/photos/1/mobile/5.jpg',
      '/photos/1/mobile/6.jpg',
    ],
  },
  {
    id: 2,
    title: 'Enium',
    type: 'Website',
    behance: 'https://www.behance.net/gallery/186238293/ENIUM-website',
    link: 'https://enium.com',
    images: [
      '/photos/2/1.jpg',
      '/photos/2/2.jpg',
      '/photos/2/3.jpg',
      '/photos/2/4.jpg',
      '/photos/2/5.jpg',
      '/photos/2/6.jpg',
    ],
    imagesMobile: [
      '/photos/2/mobile/1.jpg',
      '/photos/2/mobile/2.jpg',
      '/photos/2/mobile/3.jpg',
      '/photos/2/mobile/4.jpg',
      '/photos/2/mobile/5.jpg',
      '/photos/2/mobile/6.jpg',
      '/photos/2/mobile/7.jpg',
      '/photos/2/mobile/8.jpg',
      '/photos/2/mobile/9.jpg',
      '/photos/2/mobile/10.jpg',
    ],
  },
  {
    id: 3,
    title: 'Sovereign Series',
    type: 'Brand Identity',
    behance: 'https://www.behance.net/gallery/186067895/Sovereign-Series',
    images: [
      '/photos/3/1.jpg',
      '/photos/3/2.jpg',
      '/photos/3/3.jpg',
      '/photos/3/4.jpg',
      '/photos/3/5.jpg',
      '/photos/3/6.jpg',
    ],
    imagesMobile: [
      '/photos/3/mobile/1.jpg',
      '/photos/3/mobile/2.jpg',
      '/photos/3/mobile/3.jpg',
      '/photos/3/mobile/4.jpg',
      '/photos/3/mobile/5.jpg',
      '/photos/3/mobile/6.jpg',
    ],
  },
  {
    id: 4,
    title: 'The Cup',
    type: 'Micro-identity',
    behance: 'https://www.behance.net/gallery/183425263/THE-CUP',
    images: [
      '/photos/4/1.jpg',
      '/photos/4/2.jpg',
      '/photos/4/3.jpg',
      '/photos/4/4.jpg',
      '/photos/4/5.jpg',
      '/photos/4/6.jpg',
    ],
    imagesMobile: [
      '/photos/4/mobile/1.jpg',
      '/photos/4/mobile/2.jpg',
      '/photos/4/mobile/3.jpg',
      '/photos/4/mobile/4.jpg',
      '/photos/4/mobile/5.jpg',
      '/photos/4/mobile/6.jpg',
      '/photos/4/mobile/7.jpg',
      '/photos/4/mobile/8.jpg',
      '/photos/4/mobile/9.jpg',
      '/photos/4/mobile/10.jpg',
      '/photos/4/mobile/11.jpg',
      '/photos/4/mobile/12.jpg',
      '/photos/4/mobile/13.jpg',
    ],
  },
  {
    id: 5,
    title: 'MPWR store',
    type: 'Website',
    behance: 'https://www.behance.net/gallery/186382083/MPWR-store',
    images: [
      '/photos/5/1.jpg',
      '/photos/5/2.jpg',
      '/photos/5/3.jpg',
      '/photos/5/4.jpg',
      '/photos/5/5.jpg',
      '/photos/5/6.jpg',
    ],
    imagesMobile: [
      '/photos/5/mobile/1.jpg',
      '/photos/5/mobile/2.jpg',
      '/photos/5/mobile/3.jpg',
      '/photos/5/mobile/4.jpg',
      '/photos/5/mobile/5.jpg',
      '/photos/5/mobile/6.jpg',
      '/photos/5/mobile/7.jpg',
      '/photos/5/mobile/8.jpg',
      '/photos/5/mobile/9.jpg',
      '/photos/5/mobile/10.jpg',
      '/photos/5/mobile/11.jpg',
    ],
  },
  {
    id: 6,
    title: 'KEB building',
    type: 'Brand Identity',
    behance: 'https://www.behance.net/gallery/116032157/KEB-building',
    images: [
      '/photos/6/1.jpg',
      '/photos/6/2.jpg',
      '/photos/6/3.jpg',
      '/photos/6/4.jpg',
      '/photos/6/5.jpg',
      '/photos/6/6.jpg',
    ],
    imagesMobile: [
      '/photos/6/mobile/1.jpg',
      '/photos/6/mobile/2.jpg',
      '/photos/6/mobile/3.jpg',
      '/photos/6/mobile/4.jpg',
      '/photos/6/mobile/5.jpg',
      '/photos/6/mobile/6.jpg',
    ],
  },
  {
    id: 7,
    title: 'Sovereign Strategies',
    type: 'Website',
    link: 'https://sovereignstrategiesgroup.com/',
    behance: 'https://www.behance.net/gallery/183644553/SSG-website',
    images: [
      '/photos/7/1.jpg',
      '/photos/7/2.jpg',
      '/photos/7/3.jpg',
      '/photos/7/4.jpg',
      '/photos/7/5.jpg',
      '/photos/7/6.jpg',
    ],
    imagesMobile: [
      '/photos/7/mobile/1.jpg',
      '/photos/7/mobile/2.jpg',
      '/photos/7/mobile/3.jpg',
      '/photos/7/mobile/4.jpg',
      '/photos/7/mobile/5.jpg',
      '/photos/7/mobile/6.jpg',
    ],
  },
  {
    id: 8,
    title: 'Alpha',
    type: 'Brand Identity, Package',
    isAlpha: true,
    behance: 'https://www.behance.net/gallery/138522405/ALPHA',
    images: [
      '/photos/8/1.jpg',
      '/photos/8/2.jpg',
      '/photos/8/3.jpg',
      '/photos/8/4.jpg',
      '/photos/8/5.jpg',
      '/photos/8/6.jpg',
    ],
    imagesMobile: [
      '/photos/8/mobile/1.jpg',
      '/photos/8/mobile/2.jpg',
      '/photos/8/mobile/3.jpg',
      '/photos/8/mobile/4.jpg',
      '/photos/8/mobile/5.jpg',
      '/photos/8/mobile/6.jpg',
    ],
  },
  {
    id: 9,
    title: 'Savana',
    type: 'Brand Identity',
    behance: 'https://www.behance.net/gallery/186073059/Savana',
    images: [
      '/photos/9/1.jpg',
      '/photos/9/2.jpg',
      '/photos/9/3.jpg',
      '/photos/9/4.jpg',
      '/photos/9/5.jpg',
      '/photos/9/6.jpg',
    ],
    imagesMobile: [
      '/photos/9/mobile/1.jpg',
      '/photos/9/mobile/2.jpg',
      '/photos/9/mobile/3.jpg',
      '/photos/9/mobile/4.jpg',
      '/photos/9/mobile/5.jpg',
      '/photos/9/mobile/6.jpg',
      '/photos/9/mobile/7.jpg',
      '/photos/9/mobile/8.jpg',
      '/photos/9/mobile/9.jpg',
    ],
  },
  {
    id: 10,
    title: 'Betra',
    type: 'Micro Identity',
    behance: 'https://www.behance.net/gallery/186230209/Betra',
    images: [
      '/photos/10/1.jpg',
      '/photos/10/2.jpg',
      '/photos/10/3.jpg',
      '/photos/10/4.jpg',
      '/photos/10/5.jpg',
      '/photos/10/6.jpg',
    ],
    imagesMobile: [
      '/photos/10/mobile/1.jpg',
      '/photos/10/mobile/2.jpg',
      '/photos/10/mobile/3.jpg',
      '/photos/10/mobile/4.jpg',
      '/photos/10/mobile/5.jpg',
      '/photos/10/mobile/6.jpg',
      '/photos/10/mobile/7.jpg',
      '/photos/10/mobile/8.jpg',
      '/photos/10/mobile/9.jpg',
      '/photos/10/mobile/10.jpg',
    ],
  },
  {
    id: 11,
    title: 'La Mverte party',
    type: 'Poster design',
    behance: 'https://www.behance.net/gallery/129698033/La-Mverte-party-161021',
    images: ['/photos/11/1.jpg', '/photos/11/2.jpg', '/photos/11/3.jpg'],
    imagesMobile: [
      '/photos/11/mobile/1.jpg',
      '/photos/11/mobile/2.jpg',
      '/photos/11/mobile/3.jpg',
    ],
  },
];
