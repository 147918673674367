import React, { useState } from 'react';
import './Footer.scss';
import { Message } from 'components/Message';
import { Contacts } from 'components/Contacts';

export const Footer = ({ scrollToElement }) => {
  const [showMessage, setShowMessage] = useState(false);

  return (
    <footer className="footer">
      <img className="footer__logo--mobile" src="/symbol.svg" alt="logo" />
      <div className="footer__info">
        <Contacts setShowMessage={setShowMessage} />

        <div onClick={scrollToElement} className="footer__up">
          UP ↑
        </div>
      </div>

      <img className="footer__logo" src="/symbol.svg" alt="logo" />
      {showMessage && <Message setShowMessage={setShowMessage} />}
    </footer>
  );
};
